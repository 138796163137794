<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div>
                    <div class="lockscreen-logo">
    <a href="/ujian"><img src="@/assets/img/logo-tosca.png" alt="logo" width="250"></a>
                    </div>
                    <!-- User name -->
                    <div class="text-center">
                        <img class="img-bordered-sm" :src="info.img_url" alt="user image">
                    </div>
                    <br>
                    <div class="text-center">
                        <h6>{{info.display_name}}</h6>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="text-center">
                                <p>Kamu akan segera memulai soal latihan menggunakan e-Learning Taruna. Sistem akan merecord hasil latihan kamu. Silahkan dipersiapkan alat tulis jika diperlukan.
                                    Setelah mulai mengerjakan, maka counter waktu akan terus berjalan dan tidak dapat dihentikan hingga hasil latihan kamu dikirim.
                                    Hasil latihan kamu tetap aman terjaga privasinya, dan akan menjadi bahan evaluasi bagi LBB untuk pengambilan kebijakan di kemudian hari.</p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-lg btn-primary" @click="startTest"><i class="fas fa-arrow-right"></i> Mulai Latihan</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch, auth } from "@/libs/hxcore";
    export default {
    name: 'SoalStart',
    data() {
        return {
            kode: '',
            info: {
                display_name: 'No Name'
            }
        };
    },
    created: function () {
        let id = this.$route.params.id;
        this.kode = id;
        this.loadHasil(id);
        var info = auth.user();

        if(info)this.info = info;
    },
    methods: {
        loadHasil(id){
            authFetch("/siswa/test/start/" + id).then((res) => {
                res.json().then((json) => {
                    console.log(json);
                    if(json.success)
                    {
                        if(!json.canStart)
                        {
                            if(json.expired || json.data.finished_on)this.$router.push("/ujian-finish/" + this.kode);
                            else this.$router.push("/ujian-process/" + this.kode);
                        }                        
                    }
                });
            });
        },
      startTest: function()
      {
        authFetch('/siswa/test/start', {
                    method: 'POST',
                    body: 'kode='+this.kode
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(json => {
                    console.log(json)
                    if(json.success)
                    {
                        this.$router.push("/ujian-process/" + this.kode);
                    }
                });
      }
    }
}
</script>
<style type="text/css" scoped>
</style>